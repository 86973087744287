import React, { useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import EastIcon from '@mui/icons-material/East'
import { Container, Stack } from '@mui/material'
import AstrotekLogo from 'assets/AT_logo_blk_w_ teal.png'
import AstrotekLogoWhite from 'assets/AT_logo_blk_w_ teal_white.png'
import styles from './Header.module.sass'

const drawerWidth = 240
const navItems = ['Chains', 'Resources', 'Pricing']

function Header() {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 100) {
      setScrolled(true)
      console.warn('Scrolled')
    } else {
      setScrolled(false)
      console.warn('Not Scrolled')
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <img src={AstrotekLogo} alt="Astrotek Logo" style={{ maxWidth: 150 }} />
      <Divider sx={{ mt: 2, width: '100%' }} />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Button
        fullWidth
        sx={{
          color: '#000',
          textTransform: 'capitalize',
          fontSize: 16,
          display: { xts: 'none', xs: 'block' },
          mt: 'auto',
        }}
      >
        Login
      </Button>
    </Box>
  )

  return (
    <>
      <CssBaseline />

      <AppBar
        component="nav"
        sx={{
          backgroundColor: scrolled ? 'black.light' : 'transparent',
          boxShadow: 0,
          padding: '15px 5px',
          transition: 'all 0.3s',
        }}
        className={styles.default}
        position="fixed"
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                display: { sm: 'none' },
                color: 'white',
              }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              MUI
            </Typography> */}
            <Stack
              sx={{ '& img': { maxWidth: { lg: 200, xts: 150, xs: 120 } } }}
            >
              <img src={AstrotekLogoWhite} alt="Astrotek Logo" />
            </Stack>
            <Box
              sx={{
                display: { xs: 'none', sm: 'block' },
                textAlign: 'center',
                flex: 1,
              }}
            >
              {navItems.map((item) => (
                <Button
                  key={item}
                  sx={{
                    color: 'white',
                    px: { xl: 4, md: 3, xs: 2 },
                    textTransform: 'capitalize',
                    fontSize: 16,
                  }}
                >
                  {item}
                </Button>
              ))}
            </Box>
            <Stack direction="row" alignItems="center" ml="auto">
              <Button
                key={'Login'}
                sx={{
                  color: 'white',
                  marginRight: 1,
                  textTransform: 'capitalize',
                  fontSize: 16,
                  display: { xts: 'block', xs: 'none' },
                }}
              >
                Login
              </Button>
              <div className={`button-gradient ${styles.btnStartFree} `}>
                Start for Free{' '}
                <EastIcon
                  sx={{ ml: 1, display: { xts: 'block', xs: 'none' } }}
                />
              </div>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      {/* <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Typography>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique unde
          fugit veniam eius, perspiciatis sunt? Corporis qui ducimus quibusdam,
          aliquam dolore excepturi quae. Distinctio enim at eligendi perferendis in
          cum quibusdam sed quae, accusantium et aperiam? Quod itaque exercitationem,
          at ab sequi qui modi delectus quia corrupti alias distinctio nostrum.
          Minima ex dolor modi inventore sapiente necessitatibus aliquam fuga et. Sed
          numquam quibusdam at officia sapiente porro maxime corrupti perspiciatis
          asperiores, exercitationem eius nostrum consequuntur iure aliquam itaque,
          assumenda et! Quibusdam temporibus beatae doloremque voluptatum doloribus
          soluta accusamus porro reprehenderit eos inventore facere, fugit, molestiae
          ab officiis illo voluptates recusandae. Vel dolor nobis eius, ratione atque
          soluta, aliquam fugit qui iste architecto perspiciatis. Nobis, voluptatem!
          Cumque, eligendi unde aliquid minus quis sit debitis obcaecati error,
          delectus quo eius exercitationem tempore. Delectus sapiente, provident
          corporis dolorum quibusdam aut beatae repellendus est labore quisquam
          praesentium repudiandae non vel laboriosam quo ab perferendis velit ipsa
          deleniti modi! Ipsam, illo quod. Nesciunt commodi nihil corrupti cum non
          fugiat praesentium doloremque architecto laborum aliquid. Quae, maxime
          recusandae? Eveniet dolore molestiae dicta blanditiis est expedita eius
          debitis cupiditate porro sed aspernatur quidem, repellat nihil quasi
          praesentium quia eos, quibusdam provident. Incidunt tempore vel placeat
          voluptate iure labore, repellendus beatae quia unde est aliquid dolor
          molestias libero. Reiciendis similique exercitationem consequatur, nobis
          placeat illo laudantium! Enim perferendis nulla soluta magni error,
          provident repellat similique cupiditate ipsam, et tempore cumque quod! Qui,
          iure suscipit tempora unde rerum autem saepe nisi vel cupiditate iusto.
          Illum, corrupti? Fugiat quidem accusantium nulla. Aliquid inventore commodi
          reprehenderit rerum reiciendis! Quidem alias repudiandae eaque eveniet
          cumque nihil aliquam in expedita, impedit quas ipsum nesciunt ipsa ullam
          consequuntur dignissimos numquam at nisi porro a, quaerat rem repellendus.
          Voluptates perspiciatis, in pariatur impedit, nam facilis libero dolorem
          dolores sunt inventore perferendis, aut sapiente modi nesciunt.
        </Typography>
      </Box> */}
    </>
  )
}

export default Header
