import styles from './Feature.module.sass'
import cn from 'classnames'
import FadeinImage from 'components/FadeinImage'
import { ReactComponent as ChatStar } from '../../assets/chat-star.svg'
import { ReactComponent as ChatIcon } from '../../assets/chat-icon.svg'
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useInView } from 'react-intersection-observer'
import React, { useEffect, useRef, useState } from 'react'

interface FeatureProps {
  data: {
    title: string
    description: string
    image?: string
    flexDirection: 'row' | 'row-reverse'
    tokenTransfer?: boolean
  }
}

const ChatBox = styled(Box)(() => ({
  maxWidth: 480,
  margin: '0 auto',
  borderRadius: 30,
}))
const ChatBoxHeading = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: '#2b2e3c',
  padding: '20px 30px',
  borderRadius: '30px 30px 0 0',
  [theme.breakpoints.down('md')]: {
    padding: '15px 20px',
  },
  '& svg:first-child': {
    marginTop: 7,
  },
  '& h6': {
    marginLeft: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
}))
const ChatBoxList = styled(List)(({ theme }) => ({
  '& .MuiListItem-root': {
    wordBreak: 'break-all',
    borderBottom: '1px solid rgba(255,255,255,0.1)',
    padding: '20px',
    minHeight: 90,
    backgroundColor: '#1b1d2a',
    color: '#f7f7f7',
    [theme.breakpoints.down('md')]: {
      minHeight: 50,
      padding: 15,
    },
    '&:last-child': {
      border: 0,
    },
    '& svg': {
      flex: '0 0 20px',
      marginRight: 20,
    },
  },
}))
const ChatBoxFooter = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: '#2b2e3c',
  padding: '20px 30px',
  borderRadius: '0 0 30px 30px',
  [theme.breakpoints.down('md')]: {
    padding: '15px 20px',
  },
  '& svg': {
    marginTop: 2,
    color: theme.palette.primary.main,
  },
  '& h6': {
    marginLeft: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
}))

const chatList = [
  { title: 'From:', value: '0xb33bd56d4192e8e4e6a02e93eabc732165199346' },
  { title: 'To:', value: ' 0x6c918a7e2fec81cea41366672cc9e7ced5ff633c' },
  { title: 'Value (USD): ', value: '$6225' },
  {
    title: 'TX Hash:',
    value:
      ' 0x1ef887dd25347c058576069d97bc09a986ec26fcbf46b5d82e12b0b5820cd467',
  },
]

function Feature({ data }: FeatureProps) {
  const [isVisible, setIsVisible] = useState(false)
  const { ref: headingRef, inView: headingInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  })
  const { ref: footerRef, inView: footerInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  })

  const listItemRefs = useRef([])
  listItemRefs.current = chatList.map(
    (_, i) => listItemRefs.current[i] ?? React.createRef()
  )

  useEffect(() => {
    if (
      headingInView ||
      footerInView ||
      listItemRefs.current.some((ref: any) => ref.current && ref.current.inView)
    ) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }, [headingInView, footerInView, listItemRefs])
  return (
    <div className={styles.featureSection}>
      <Container maxWidth="xl">
        <Grid
          container
          alignItems="center"
          columnSpacing={3}
          flexDirection={data.flexDirection}
        >
          <Grid item md={5} xs={12}>
            <div
              className={cn(styles.textContainer, {
                [styles.reverse]: data.flexDirection === 'row-reverse',
              })}
            >
              <h1 className={styles.featureTitle}>{data.title}</h1>
              <p>{data.description}</p>
            </div>
          </Grid>
          {data.image && (
            <Grid item md={7}>
              <div className={styles.featureImage}>
                <FadeinImage src={data.image} alt={data.title} />
              </div>
            </Grid>
          )}
          {data.tokenTransfer && (
            <Grid item md={7} xs={12}>
              <ChatBox>
                <ChatBoxHeading
                  direction="row"
                  ref={headingRef}
                  className={cn(styles.fadeInUp, {
                    [styles.visible]: isVisible,
                    [styles.hidden]: !isVisible,
                  })}
                  sx={{
                    '&.MuiStack-root': {
                      transitionDelay: isVisible ? `100ms` : '0ms',
                    },
                  }}
                >
                  <ChatStar />
                  <Typography variant="h6">
                    ChainLink Token (LINK) Transfer
                  </Typography>
                  <KeyboardArrowDownIcon sx={{ ml: 'auto' }} />
                </ChatBoxHeading>
                <ChatBoxList disablePadding>
                  {chatList.map((item, index) => {
                    const delay = isVisible ? `${(index + 1) * 200}ms` : '0ms'
                    return (
                      <ListItem
                        ref={listItemRefs.current[index]}
                        key={index}
                        className={cn(styles.fadeInUp, {
                          [styles.visible]: isVisible,
                          [styles.hidden]: !isVisible,
                        })}
                        sx={{
                          transitionDelay: delay,
                        }}
                      >
                        <ChatIcon />
                        <Typography component="p">
                          <Typography
                            component="span"
                            fontWeight="bold"
                            mr={0.5}
                          >
                            {item.title}
                          </Typography>
                          {item.value}
                        </Typography>
                      </ListItem>
                    )
                  })}
                </ChatBoxList>
                <ChatBoxFooter
                  direction="row"
                  ref={footerRef}
                  className={cn(styles.fadeInUp, {
                    [styles.visible]: isVisible,
                    [styles.hidden]: !isVisible,
                  })}
                  sx={{
                    '&.MuiStack-root': {
                      transitionDelay: isVisible
                        ? `${(chatList.length + 1) * 200}ms`
                        : '0ms',
                    },
                  }}
                >
                  <AddCircleOutlineIcon />
                  <Typography variant="h6">Inspect All Transactions</Typography>
                </ChatBoxFooter>
              </ChatBox>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  )
}

export default Feature
