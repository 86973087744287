// import React from 'react'
// import styles from './Partners.module.sass'

// function Partners() {
//   return (
//     <div className={styles.container}>
//       <h2>TRUSTED BY INDUSTRY LEADERS</h2>
//       <div className={styles.partners}>
//         <img
//           src="https://miro.medium.com/v2/resize:fit:549/1*nDGCsYNAJ3K-oxVqy9i8lA.png"
//           alt="Partner 1"
//         />
//         <img
//           src="https://miro.medium.com/v2/resize:fit:549/1*nDGCsYNAJ3K-oxVqy9i8lA.png"
//           alt="Partner 2"
//         />
//         <img
//           src="https://miro.medium.com/v2/resize:fit:549/1*nDGCsYNAJ3K-oxVqy9i8lA.png"
//           alt="Partner 3"
//         />
//         <img
//           src="https://miro.medium.com/v2/resize:fit:549/1*nDGCsYNAJ3K-oxVqy9i8lA.png"
//           alt="Partner 4"
//         />
//       </div>
//     </div>
//   )
// }

// export default Partners

/////////////////////////////////////////////////////////////////////

import { Box, Container, Typography } from '@mui/material'
import styles from './Partners.module.sass'

function Partners() {
  return (
    <Box py={{ lg: 12, md: 8, xs: 4 }}>
      <Container maxWidth="xl">
        <Typography fontWeight="bold" variant="h4" textAlign="center" mb={5}>
          BUILDING WITH THE BEST
        </Typography>
        <div className={styles.partners}>
          <div className={styles.partnersImg}>
            <img
              src={require('../../assets/partner_assets/Ankr_White Logo.png')}
              alt="Ankr"
            />
          </div>
          <div className={styles.partnersImg}>
            <img
              src={require('../../assets/partner_assets/apeworx_logo.png')}
              alt="Apeworx"
            />
          </div>
          <div className={styles.partnersImg}>
            <img
              src={require('../../assets/partner_assets/CF_logo_stacked_singlecolor_wht.png')}
              alt="Cloudflare"
            />
          </div>
          <div className={styles.partnersImg}>
            <img
              src={require('../../assets/partner_assets/infura_lockup_white.png')}
              alt="Infura"
            />
          </div>
          <div className={styles.partnersImg}>
            <img
              src={require('../../assets/partner_assets/tinygrad.png')}
              alt="Infura"
            />
          </div>
          {/* <img
            src={require('../../assets/partner_assets/Property 1=One Colour.png')}
            alt="Tinygrad"
          /> */}
        </div>
      </Container>
    </Box>
  )
}

export default Partners
