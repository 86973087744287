import { styled } from '@mui/system'
import bannerImg from '../../assets/banner-img.png'
import { Box, Container, Grid, Typography } from '@mui/material'
import bannerPattern from '../../assets/bg-pattern.png'
import bannerShape from '../../assets/bg-shape2.png'
import shape1 from '../../assets/shape1.png'
import shape2 from '../../assets/shape2.png'
import EastIcon from '@mui/icons-material/East'

const HeroSection = styled(Box)(() => ({
  position: 'relative',
  zIndex: 1,
  minHeight: '100vh',
  padding: '150px 0 0',
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&:before': {
    content: '""',
    position: 'absolute',
    background:
      'radial-gradient(circle, rgba(252,70,70,1) 0%, rgba(252,70,70,0) 40%)',
    borderRadius: '100%',
    top: '-25%',
    right: '-400px',
    height: 700,
    opacity: 0.5,
    width: 700,
    zIndex: 1,
    pointerEvents: 'none',
  },
  '&:after': {
    content: '""',
    backgroundImage: `url(${bannerPattern})`,
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0.3,
    bottom: 0,
    right: 0,
    zIndex: -1,
    backgroundPosition: 'bottom left',
    backgroundRepeat: 'no-repeat',
    pointerEvents: 'none',
  },
}))
const BgImageShape = styled(Box)(() => ({
  backgroundImage: `url(${bannerShape})`,
  pointerEvents: 'none',
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  opacity: 0.1,
  backgroundRepeat: 'no-repeat',
  zIndex: -1,
}))
const BannerImage = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: '5%',
  width: '50%',
  [theme.breakpoints.down('xl')]: {
    right: 0,
    width: '60%',
  },
  [theme.breakpoints.down('md')]: {
    position: 'static',
    width: '100%',
  },
}))
const Shape = styled(Box)(() => ({
  '& img': {
    position: 'absolute',
    pointerEvents: 'none',
    '&:first-child': {
      top: '-5%',
      left: '5%',
      opacity: 0.5,
    },
    '&:last-child': {
      top: '10%',
      left: 0,
      opacity: 0.5,
    },
  },
}))
const BannerHeading = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: 64,
  fontWeight: 'bold',
  background: '#fff',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  lineHeight: 1.1,
  [theme.breakpoints.down('xl')]: {
    fontSize: 54,
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: 48,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 40,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 34,
  },
  [theme.breakpoints.down('xsm')]: {
    fontSize: 24,
  },
  '& span': {
    background: 'linear-gradient(90deg, #EA526F 0%, #8101B4 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextStroke: '4px transparent',
    color: theme.palette.black.main,
    WebkitTextFillColor: theme.palette.black.main,
    [theme.breakpoints.down('xsm')]: {
      WebkitTextStroke: '2px transparent',
    },
  },
}))
const Hero = () => {
  return (
    <HeroSection>
      <BgImageShape />
      <Shape>
        <img src={shape1} alt="" />
        <img src={shape2} alt="" />
      </Shape>
      <Container maxWidth="xl">
        <Grid container>
          <Grid
            item
            md={5.5}
            xs={12}
            textAlign={{ md: 'left', xs: 'center' }}
            mb={{ md: 0, xs: 4 }}
          >
            <BannerHeading component="h1" mb={{ sm: 4, xsm: 3, xs: 2 }}>
              One-Click Portfolio <span>Insights...</span>
            </BannerHeading>
            <Typography
              variant="body1"
              mb={{ sm: 3, xs: 2 }}
              sx={{ mx: { md: 0, xs: 'auto' } }}
              maxWidth={450}
              fontSize={{ sm: 18, xs: 16 }}
            >
              Entire wallet transaction history and granular logs, fully decoded
              and labeled.
            </Typography>
            <button className={'button-gradient'}>
              Start for Free <EastIcon sx={{ ml: 1 }} />
            </button>
          </Grid>
        </Grid>
      </Container>
      <BannerImage>
        <img src={bannerImg} alt="Hero Image" />
      </BannerImage>
    </HeroSection>
  )
}

export default Hero

// import React from 'react';
// import styles from './Hero.module.sass';
// import heroVideo from '../../assets/App_Overview_Long_Version.mp4'; // Adjust the path according to your folder structure

// function Hero() {
//   return (
//     <div className={styles.container}>
//       <div className={styles.heroImage}>
//         <video className={styles.video} autoPlay loop muted>
//           <source src={heroVideo} type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>
//       </div>
//       <div className={styles.contentHolder}>
//         <h1 className={styles.gradientText}>
//           One-Click Portfolio Insights...
//         </h1>
//         <p>
//           Entire wallet transaction history and granular logs, fully decoded and
//           labeled.
//         </p>
//         <button className={'button-primary'}>Start for Free</button>
//       </div>
//     </div>
//   );
// }

// export default Hero;
