import styles from './Pricing.module.sass'
import cn from 'classnames'

function Pricing() {
  return (
    <div className={styles.pricingSection}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.pricingContainer}>
              <div className={styles.pricingHeader}>Package 1</div>
              <div className={styles.price}>
                $35<span>/Month</span>
              </div>
              <div className={styles.pricingContent}>
                {['Feature 1', 'Feature 2', 'Feature 3'].map((item, index) => (
                  <div className={'hello'} key={index}>
                    <p>{item}</p>
                  </div>
                ))}
              </div>
              <div className={styles.pricingFooter}>
                <div
                  style={{
                    width: '80%',
                  }}
                  className={'button-stroke'}
                >
                  Get Started
                </div>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn(styles.pricingContainer, styles.alternate)}>
              <div className={styles.pricingHeader}>Package 2</div>
              <div className={styles.price}>
                $150<span>/Month</span>
              </div>
              <div className={styles.pricingContent}>
                {[
                  'Feature 1',
                  'Feature 2',
                  'Feature 3',
                  'Feature 4',
                  'Feature 5',
                  'Feature 6',
                  'Feature 7',
                ].map((item, index) => (
                  <div className={'hello'} key={index}>
                    <p>{item}</p>
                  </div>
                ))}
              </div>
              <div className={styles.pricingFooter}>
                <div
                  style={{
                    width: '80%',
                  }}
                  className={'button-gradient'}
                >
                  Get Started
                </div>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn(styles.pricingContainer)}>
              <div className={styles.pricingHeader}>Package 3</div>
              <div className={styles.price}>
                $500<span>/Month</span>
              </div>
              <div className={styles.pricingContent}>
                {['Feature 1', 'Feature 2', 'Feature 3'].map((item, index) => (
                  <div className={'hello'} key={index}>
                    <p>{item}</p>
                  </div>
                ))}
              </div>
              <div className={styles.pricingFooter}>
                <div
                  style={{
                    width: '80%',
                  }}
                  className={'button-stroke'}
                >
                  Get Started
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing
