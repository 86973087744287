// import Partners from 'components/Partners'
// import './App.css'
// import Header from './components/Header'
// import Feature from 'components/Feature'
// import Hero from 'components/Hero'
// import Pricing from 'components/Pricing'
// import Footer from 'components/Footer'

// function App() {
//   return (
//     <div
//       className="App"
//       style={{
//         width: '100%',
//         minHeight: '100vh',
//         paddingTop: 100,
//         backgroundColor: '#0C0A17',
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'center',
//       }}
//     >
//       <Header />
//       <div style={{ maxWidth: 1320 }}>
//         <Hero />
//         <Partners />
//         <Feature
//           data={{
//             title: 'Complete wallet activity',
//             description:
//               'Showcase the most comprehensive wallet history across NFTs, token transfers, native transactions and internal transactions.',
//             image:
//               'https://moralis.io/wp-content/uploads/pages/walletPage/Moralis-Wallet-Activity.svg',
//             flexDirection: 'row',
//           }}
//         />

//         <Feature
//           data={{
//             title: 'Decoded transactions and events',
//             description:
//               'Connect the dots and understand what’s happening on the blockchain through our data decoding transaction labeling & method decoding',
//             image:
//               'https://moralis.io/wp-content/uploads/pages/walletPage/Moralis-Decoded-Transactions.svg',
//             flexDirection: 'row-reverse',
//           }}
//         />

//         <Pricing />
//       </div>
//       <Footer />
//     </div>
//   )
// }

// export default App

import Partners from 'components/Partners'
import Header from './components/Header'
import Feature from 'components/Feature'
import Hero from 'components/Hero'
import Pricing from 'components/Pricing'
import Footer from 'components/Footer'

// Importing images from the local assets directory
import { Container, ThemeProvider, createTheme } from '@mui/material'
import demoOne from './assets/demo_one.png'
import walletInput from './assets/wallet_input.svg'
import './App.css'
import '@mui/material/styles'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    xts: true
    xsm: true
    sm: true
    md: true
    lg: true
    xl: true
  }
}
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    black: Palette['primary']
  }
  interface PaletteOptions {
    black?: PaletteOptions['primary']
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xts: 480,
      xsm: 575,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: '#81d1dd',
    },
    black: {
      main: '#0d0a17',
      light: '#161127',
    },
  },
  typography: {
    fontFamily: ["'Saira', sans-serif"].join(','),
  },
})

function App() {
  return (
    <div
      className="App"
      style={{
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#0C0A17',
      }}
    >
      <ThemeProvider theme={theme}>
        <Header />
        <Hero />
        <Partners />
        <Feature
          data={{
            title: 'Complete wallet activity',
            description:
              'Showcase the most comprehensive wallet history across NFTs, token transfers, native transactions and internal transactions.',
            flexDirection: 'row',
            tokenTransfer: true,
          }}
        />

        <Feature
          data={{
            title: 'Complete wallet activity',
            description:
              'Showcase the most comprehensive wallet history across NFTs, token transfers, native transactions and internal transactions.',
            image: demoOne,
            flexDirection: 'row',
          }}
        />

        <Feature
          data={{
            title: 'Decoded transactions and events',
            description:
              'Connect the dots and understand what’s happening on the blockchain through our data decoding transaction labeling & method decoding',
            image: walletInput,
            flexDirection: 'row-reverse',
          }}
        />
        <Container maxWidth="xl">
          <Pricing />
        </Container>
        <Footer />
      </ThemeProvider>
    </div>
  )
}

export default App
