import { Box, Container, Stack, styled } from '@mui/material'
import styles from './Footer.module.sass'

const FooterSection = styled(`footer`)(({ theme }) => ({
  backgroundColor: theme.palette.black.light,
  padding: '20px 0',
}))

function Footer() {
  return (
    <FooterSection>
      <Container maxWidth="xl">
        <Stack
          direction="row"
          flexWrap="wrap"
          sx={{ justifyContent: 'center' }}
        >
          {['Privacy Policy', 'Terms & Conditions'].map((item, index) => (
            <Box mr={2}>{item}</Box>
          ))}
          <Stack
            ml="auto"
            sx={{
              width: { xts: 'auto', xs: '100%', textAlign: 'center' },
              mt: { xts: 0, xs: 3 },
            }}
          >
            Astrotek LLC
          </Stack>
        </Stack>
      </Container>
    </FooterSection>
  )
}

export default Footer
