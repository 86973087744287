import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './FadeinImage.module.sass'
import cn from 'classnames'

function FadeinImage({ src, alt }: { src: string; alt: string }) {
  const [isVisible, setIsVisible] = useState(false)
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  })
  React.useEffect(() => {
    if (inView) {
      console.log(src + ' in view')
      setIsVisible(true)
    } else {
      console.log(src + ' not in view')
      setIsVisible(false)
    }
  }, [inView])

  return (
    <div
      className={cn(styles.fadeinImage, {
        [styles.visible]: isVisible,
        [styles.hidden]: !isVisible,
      })}
      ref={ref}
    >
      <img src={src} alt={alt} />
    </div>
  )
}

export default FadeinImage
